<template>
  <div class="static">
    <div class="mt-16 mb-12">
      <h3 class="mb-2 text-sm font-bold tracking-widest text-gray-500 uppercase print:hidden">Testimonials</h3>
      <div class="py-4 px-12 mb-8 bg-white rounded-lg shadow-lg dark:bg-gray-900 print:shadow-none">
        <div class="flex overflow-hidden justify-end -mt-16 print:hidden">
          <div class="overflow-hidden w-24 h-24 rounded-full">
            <fade-in-out>
              <div class="block relative">
                <img
                  class="object-cover mx-auto w-24 h-24 rounded-full border-2 border-indigo-400 transition-all dark:border-gray-600"
                  :src="testimonial.photo"
                  v-show="isDisplayed"
                  :alt="`Testimonial from ${testimonial.author.name}`"
                >
              </div>
            </fade-in-out>
          </div>
        </div>
        <div class="overflow-hidden overflow-y-scroll mt-4 h-64">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="inline-block mb-8 w-8 h-8 text-gray-400" viewBox="0 0 975.036 975.036">
            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
          </svg>
          <fade-in-out>
            <p class="italic text-gray-600 transition-all print:text-gray-800 dark:text-gray-500" v-if="isDisplayed">{{ testimonial.body }}</p>
          </fade-in-out>
        </div>
        <div class="overflow-hidden mt-6 h-24">
          <fade-in-out>
            <div class="flex flex-wrap text-right" v-if="isDisplayed">
              <span class="w-full text-sm leading-snug text-indigo-400 print:text-gray-800 dark:text-indigo-400">{{ testimonial.author.title }}</span>
              <span class="w-full">
                <a class="text-lg tracking-wide leading-snug text-gray-800 cursor-pointer hover:text-gray-900 dark:text-gray-200 dark:hover:text-gray-600"
                   :href="testimonial.linkedIn"
                 >
                  <h6 class="font-semibold">{{ testimonial.author.name }}</h6>
                </a>
              </span>
              <span class="top-0 mb-4 w-full text-sm text-gray-400 print:text-gray-700 dark:text-gray-600">{{ testimonial.organization }}</span>
            </div>
          </fade-in-out>
        </div>
      </div>
      <div class="relative print:hidden">
        <button @click="previous"
                class="absolute top-0 left-0 -mt-32 -ml-6 w-12 h-12 text-2xl text-indigo-600 bg-white rounded-full shadow-md print:text-gray-600 dark:text-indigo-600 dark:bg-black hover:shadow-xl hover:text-indigo-400 focus:text-indigo-400 focus:outline-none focus:shadow-outline">
          <span class="block" style="transform: scale(-1);">&#x279c;</span>
        </button>
        <button @click="next"
                class="absolute top-0 right-0 -mt-32 -mr-6 w-12 h-12 text-2xl text-indigo-600 bg-white rounded-full shadow-md print:text-gray-600 dark:text-indigo-600 dark:bg-black hover:shadow-xl hover:text-indigo-400 focus:text-indigo-400 focus:outline-none focus:shadow-outline">
          <span class="block" style="transform: scale(1);">&#x279c;</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import katherineImg from '@/assets/img/portraits/katherine-regnier.jpeg';
import juniorImg from '@/assets/img/portraits/junior-freitas.jpeg';
import andreImg from '@/assets/img/portraits/andre-doucette.jpeg';
import chrisImg from '@/assets/img/portraits/chris-powell.jpeg';
import anthonyImg from '@/assets/img/portraits/anthony-grant.jpeg';
import geraldImg from '@/assets/img/portraits/gerald-ankunda.jpeg';
import julianImg from '@/assets/img/portraits/julian-salari.jpeg';
import gavinImg from '@/assets/img/portraits/gavin-servai.jpeg';

const chunk = (arr, size) => Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));

export default {
  name: 'testimonials',
  data() {
    return {
      isDisplayed: true,
      testimonials: [
        {
          id: 1,
          body: 'Jamal is a rockstar. Not because of his engineering skills (which are completely solid) - but because he\'s truly passionate and puts his heart into everything he does. I have found Jamal on numerous occassions in the office on the weekend working because he wanted to ensure his team delivered. Jamal takes is work seriously. He is thoughtful. He is a fabulous leader, and he would be an asset to any team. I feel fortunate to work beside him at Coconut.',
          author: {
            name: 'Katherine Regnier',
            title: 'Chief Executive Officer',
          },
          photo: katherineImg,
          organization: 'Coconut Software',
          additional: 'Winner - "Women Enterpreneur of the Year" - Prairie Region',
          linkedIn: 'https://www.linkedin.com/in/katherineregnier/',
        },
        {
          id: 2,
          body: 'I rarely come across real stand-out talent, like Jamal. Jamal is a great team member and always ready to help, teach, and share knowledge. He\'s a pragmatic programmer focused on maintaining the quality of your code, ensuring software\'s quality even more. I would definitely recommend Jamal for any team or project.',
          author: {
            name: 'Jose de Freitas Junior',
            title: 'Software Developer',
          },
          photo: juniorImg,
          organization: 'Coconut Software',
          linkedIn: 'https://www.linkedin.com/in/juniormfreitas/',
        },
        {
          id: 3,
          body: 'Jamal is a careful and thorough senior developer. He ensures that edge and test cases are understood and accounted for before writing any code. During team meetings, he listens to others\' feedback and takes questions as learning opportunities both for him to teach more junior devs, and for him to learn from more experience devs. I would work with Jamal anytime.',
          author: {
            name: 'Andre Doucette',
            title: 'Product Manager',
          },
          photo: andreImg,
          organization: 'Coconut Software',
          linkedIn: 'https://www.linkedin.com/in/andreldoucette/',
        },
        {
          id: 4,
          body: 'Jamal\'s knowledge is probably only surpassed by his continued desire to learn, and to teach. He led one of our development teams, and also taught programming lessons to several staff. He was always willing to assist with projects or experiments. Our team is better for having Jamal on it.',
          author: {
            name: 'Chris Powell',
            title: 'Manager of Privacy, Security, and Compliance',
          },
          photo: chrisImg,
          organization: 'Coconut Software',
          linkedIn: 'https://www.linkedin.com/in/christopherspowell/',
        },
        {
          id: 5,
          body: 'Jamal is truly a huge asset to any team he joins. During our time together at Lighthouse Labs, his accelerated learning ability and resourcefulness allowed him to deliver on objectives within projects and assignments with great turnaround time - without sacrificing code quality or clarity. His last venture in Africa proves that he is also purpose driven with his work, a quality that is priceless in a working environment that promotes user-centric first approaches. I look forward to the next opportunity to get to work with Jamal, whatever the project may be.',
          author: {
            name: 'Anthony Grant',
            title: 'Software Developer',
          },
          photo: anthonyImg,
          organization: 'eCommerce Product Experience',
          linkedIn: 'https://www.linkedin.com/in/anthonylgrant/',
        },
        {
          id: 6,
          body: 'I spent the past 6 months working side by side with Jamal and he is a very hardworking, dedicated and talented individual. His advice and pointers always led to solutions in an elegant and efficient way. My skills in Javascript and particularly VueJs have greatly improved thanks to his tutelage. Outside office hours he\'s very interesting and fun, and one of my very best friends. Without question, he\'s one of the best software developers I\'ve had the pleasure of working with.',
          author: {
            name: 'Gerald Ankunda',
            title: 'Senior Software Engineer',
          },
          photo: geraldImg,
          organization: 'Ensibuuko',
          linkedIn: 'https://www.linkedin.com/in/gerald-ankunda-20208310/',
        },
        {
          id: 7,
          body: 'I have had the pleasure of working with Jamal on numerous projects in the past, involving languages and frameworks such as JavaScript, VUE.js, and React.js, among others. Jamal always provides high-end instructor-level explanations and guidance, while also being an extremely quick learner.   Always a self-starter, Jamal is an ideal colleague and partner for anyone looking to add to their team or head a project. I hope to work with Jamal again in the near future.',
          author: {
            name: 'Julian Salari',
            title: 'Digital Marketing and Conversion Strategist',
          },
          photo: julianImg,
          organization: 'Digital Hospitality',
          linkedIn: 'https://www.linkedin.com/in/juliansalari/',
        },
        {
          id: 8,
          body: 'Jamal is amongst strongest engineers that I\'ve had the pleasure of working with. I\'ve found that most engineers fall into one of two groups: deeply technical but lacking intensity and business sense, or vice-versa. Jamal is a combination of both; a reliable engineer who can step up when the moment arises as well as provide deep technical guidance.',
          author: {
            name: 'Gavin Servai',
            title: 'Director of Engineering',
          },
          photo: gavinImg,
          organization: 'CareCru',
          linkedIn: 'https://www.linkedin.com/in/gavin-servai/',
        },
      ],
      index: 0,
    };
  },
  computed: {
    testimonial() {
      return this.testimonials[this.index];
    },
    chunks() {
      return chunk(this.testimonials, 2);
    },
  },
  methods: {
    next() {
      this.isDisplayed = false;
      if (this.index === this.testimonials.length - 1) {
        this.index = 0;
        setTimeout(() => {
          this.isDisplayed = true;
        }, 250);
        return;
      }
      this.index += 1;
      setTimeout(() => {
        this.isDisplayed = true;
      }, 500);
    },
    previous() {
      this.isDisplayed = false;
      if (this.index === 0) {
        this.index = this.testimonials.length - 1;
        setTimeout(() => {
          this.isDisplayed = true;
        }, 250);
        return;
      }
      this.index -= 1;
      setTimeout(() => {
        this.isDisplayed = true;
      }, 250);
    },
  },
  mounted() {
    this.index = Math.floor(Math.random() * this.testimonials.length);
  },
};
</script>
