<template>
  <content-section :print="false">
    <span slot="heading" class="text-gray-500 uppercase tracking-widest font-bold mb-2 text-sm">Made with</span>
    <div class="justify-center md:space-y-0 flex-col md:flex-row space-y-20 flex md:flex-1 sm:justify-between md:space-x-10">
      <a class="focus:opacity-500 hover:opacity-100" :href="link.url" target="_blank" v-for="link in links" :key="link.url">
        <img class="md:flex md:flex-1 opacity-50 greyscale-0 dark:greyscale-1 logo first:mt-0 mt-10 dark:saturate-1 opacity-100 hover:opacity-50" :src="link.src" :alt="link.name">
      </a>
    </div>
  </content-section>
</template>

<script>
import vue from '@/assets/img/vuejs.svg';
import tailwind from '@/assets/img/tailwindcss.svg';
import refactoringUi from '@/assets/img/refactoringui.svg';

export default {
  name: 'made-with',
  data() {
    return {
      links: [
        {
          url: 'https://vuejs.org/',
          src: vue,
          name: 'VueJS',
        },
        {
          url: 'https://tailwindcss.com/',
          src: tailwind,
          name: 'TailwindCSS',
        },
        {
          url: 'https://refactoringui.com/',
          src: refactoringUi,
          name: 'RefactoringUI',
        },
      ],
    };
  },
};
</script>
