import Vue from 'vue';
import App from './App.vue';
import router from './router';

import './assets/scss/app.scss';

window.scrollTo = (element) => {
  document.querySelector(element).scrollIntoView({
    behavior: 'smooth',
  });
};

const files = require.context('./', true, /\.vue$/i);
files.keys().map((key) => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    dateFor({ dates: { start, end } }) {
      if (start === end) { return start; }
      return [start, end].filter(Boolean).join(' to ');
    },
  },
});

Vue.filter('capitalize', (str) => str.toString().charAt(0).toUpperCase() + str.toString().slice(1));

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
