<template>
  <div class="flex flex-1">
    <div class="ml-10">
      <h1 class="text-3xl text-indigo-600 mt-3 w-full subpixel-antialiased">Looking for a software developer?</h1>
      <h2 class="font-normal text-3xl text-indigo-800 leading-none my-3 w-full">Get in touch</h2>
      <div class="w-full text-center">
        <form action="#">
          <div class="max-w-sm mx-auto p-1 pr-0 flex items-center">
            <input type="email" placeholder="jenny.rosen@example.com" class="flex-1 appearance-none rounded shadow p-3 text-gray-dark mr-2 focus:outline-none">
            <button type="submit" class="appearance-none bg-indigo-600 text-white text-base font-semibold tracking-wide uppercase p-3 rounded shadow hover:bg-indigo-light">Get started</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contact-form',
};
</script>
