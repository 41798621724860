<template>
  <div class="p-6 mb-6 w-full bg-white dark:bg-gray-900 rounded-lg shadow-xl print:shadow-none last:mb-0 print:p-0 page-break-inside-avoid print:rounded-none">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'card',
};
</script>
