<template>
  <div>
    This will be the blog
  </div>
</template>

<script>
export default {
  name: 'blog',
};
</script>
