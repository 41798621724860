<template>
  <div class="flex items-baseline text-sm tracking-tight text-gray-500 print:text-gray-700 print:text-gray-700">
    <i class="mr-1 text-gray-600 print:text-gray-800 fas fa-fw fa-map-marker-alt"></i>
    <p>
      {{ locationsList }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'location-indicator',
  props: {
    locations: {
      type: Array,
      required: true,
    },
  },
  computed: {
    locationsList() {
      return this.locations.join(' / ');
    },
  },
};
</script>
