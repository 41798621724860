<template>
  <content-section :print="false">
    <span slot="heading">Technologies</span>
    <card>
      <div class="mb-6 last:mb-0" v-for="category in technologies.categories" :key="category.title">
        <h3 class="title">{{ category.title }}</h3>
        <a
          :href="skill.link || '#'"
          :key="skill.name"
          class="inline-block py-1 px-2 mt-2 mr-1 mb-0 text-xs font-bold tracking-wider text-white uppercase bg-indigo-400 rounded-full bg-gradient dark:bg-indigo-700 dark:text-gray-100"
          target="_blank"
          v-for="skill in category.skills"
        >
          {{ skill.name }}
        </a>
      </div>
    </card>
  </content-section>
</template>

<script>
export default {
  name: 'resume-technologies',
  data() {
    return {
      technologies: {
        title: 'Technical Skills',
        categories: {
          databases: {
            title: 'Databases',
            skills: [
              {
                name: 'DynamoDB',
                link: 'https://aws.amazon.com/dynamodb/',
              },
              {
                name: 'Firebase',
                link: 'https://firebase.google.com/',
              },
              {
                name: 'MySQL',
                link: 'https://www.mysql.com/',
              },
              {
                name: 'Redis',
                link: 'https://redis.io/',
              },
              { name: 'SQLite', link: 'https://www.sqlite.org/index.html' },
            ],
          },
          languages: {
            title: 'Languages',
            skills: [
              { name: 'JavaScript', link: 'https://www.javascript.com/' },
              { name: 'PHP', link: 'https://www.php.net' },
              { name: 'SQL', link: '#' },
            ],
          },
          frameworks: {
            title: 'Frameworks',
            skills: [
              { name: 'BackboneJS', link: '#' },
              { name: 'Laravel', link: '#' },
              { name: 'Marionette', link: '#' },
              { name: 'React', link: '#' },
              { name: 'Vue', link: '#' },
            ],
          },
          virtualization: {
            title: 'Virtualization',
            skills: [
              { name: 'Docker', link: '#' },
              { name: 'Vagrant', link: '#' },
            ],
          },
          architectures: {
            title: 'Architectures',
            skills: [
              { name: 'Agile', link: '#' },
              { name: 'Design Patterns', link: '#' },
              { name: 'Scrum', link: '#' },
              { name: 'REST', link: '#' },
            ],
          },
          versionControl: {
            title: 'Version Control',
            skills: [
              { name: 'Git', link: '#' },
            ],
          },
          oS: {
            title: 'Operating Systems',
            skills: [
              { name: 'Shell Scripting', link: '#' },
              { name: 'Unix/Linux', link: '#' },
            ],
          },
        },
      },
    };
  },
};
</script>
