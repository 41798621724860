<template>
  <div id="app" class="h-full subpixel-antialiased bg-indigo-100 dark:bg-gray-800 print:bg-transparent">
    <navigation-bar />
    <div class="container relative py-2 px-6 mx-auto print:px-0">
      <router-view />
    </div>
    <layout-footer />
  </div>
</template>

<script>
export default {
  name: 'app',
};
</script>
