<template>
  <div class="hidden h-40 group md:block print:block print:h-40 print:mt-10">
    <div class="relative group-hover:blur-1 group-hover:pop-in">
      <div class="flex items-center space-x-24">
        <div class="flex-1" v-for="logo in logos" :key="logo.name">
          <img :src="logo.src" :alt="logo.name">
        </div>
      </div>
    </div>
    <div class="relative">
      <div class="absolute right-0 bottom-0 left-0 mb-10 text-center opacity-0 group-hover:opacity-100">
        <div class="flex w-full text-center align-center">
          <div class="relative w-full">
            <a
              class="inline-flex items-center py-2 px-4 font-semibold text-white uppercase bg-indigo-500 rounded shadow-xl cursor-pointer dark:text-gray-100 dark:bg-indigo-700 dark:hover:bg-indigo-800 hover:bg-indigo-400 hover:shadow-2xl group-hover:pop-out"
              @click.prevent="scroll">
              See Who I've Worked With
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carecru from '@/assets/img/carecru-gray.png';
import careerFoundry from '@/assets/img/careerfoundry-gray.png';
import coconutSoftware from '@/assets/img/coconut-software-gray.png';
import ensibuuko from '@/assets/img/ensibuuko-gray.png';

export default {
  name: 'worked-at',
  methods: {
    scroll() {
      global.scrollTo('#experience');
    },
  },
  data() {
    return {
      logos: [
        {
          name: 'CareCru',
          src: carecru,
        },
        {
          name: 'Career Foundry',
          src: careerFoundry,
        },
        {
          name: 'Coconut Software',
          src: coconutSoftware,
        },
        {
          name: 'Ensibuuko',
          src: ensibuuko,
        },
      ],
    };
  },
};
</script>
