<template>
  <div id="bubbles" class="absolute inset-0 object-fill z-10 print:hidden"></div>
</template>

<script>
import 'particles.js';

import options from '@/components/particles.json';

export default {
  name: 'particles',
  mounted() {
    // eslint-disable-next-line
    particlesJS('bubbles', {
      ...this.options,
      ...options,
    });
  },
  computed: {
    dark() {
      return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    },
    options() {
      if (this.dark) {
        return {
          color: {
            value: [
              '#5a67d8',
              '#4c51bf',
              '#434190',
            ],
          },
        };
      }
      return {
        color: {
          value: ['#ebf4ff', '#c3dafe', '#a3bffa'], // indigo 100, 200, 300
        },
      };
    },
  },
};
</script>
