<template>
  <section :class="sectionClass">
    <h3 class="mb-2 text-sm font-bold tracking-widest text-gray-500 print:text-gray-900 dark:text-gray-500 uppercase print:text-base print:mb-4">
      <slot name="heading">Insert Heading</slot>
    </h3>
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: 'content-section',
  props: {
    print: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    sectionClass() {
      return {
        'mt-10 mb-0 last:mb-20': true,
        'print:hidden': this.print !== true,
      };
    },
  },
};
</script>
