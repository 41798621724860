<template>
  <content-section class="page-break-inside-avoid">
    <span slot="heading">Skills Overview</span>
    <card>
      <div class="-ml-4">
        <ul class="fa-ul">
          <li v-for="skill in skills" :key="skill" class="mb-4">
            <span class="fa-li"><i class="text-green-400 fas fa-check-circle text-md dark:text-green-500"></i></span>
            <p class="text-gray-600 print:text-gray-800 dark:text-gray-600">{{ skill }}</p>
          </li>
        </ul>
      </div>
    </card>
  </content-section>
</template>

<script>
export default {
  name: 'resume-skills-overview',
  data() {
    return {
      skills: [
        'Thorough understanding and extensive working experience with CS fundamentals including automation, algorithmic processes, data modelling, and various design patterns',
        'Experience with database design, solution architecture, and development with MySQL, Eloquent ORM, and cloud hosting with Google’s Firebase',
        'Planning, prototyping, developing, and testing for web, mobile/tablet',
        'Well-practiced in and strong advocate of test-driven-development',
        'Comprehensive understanding of continuous integration and content delivery systems',
        'Familiarity with secure web application practices (authentication, authorization, encryption)',
        'Experience with setup, implementation, and integration with various payment gateways (Stripe, Moneris) and merchant account providers',
      ],
    };
  },
};
</script>
