<template>
  <div>
    <div @mouseover="open" @mouseleave="close" :class="contextMenuTriggerClass">
      <slot name="trigger"></slot>
    </div>

    <transition name="pop-out-quick">
      <div :class="contextMenuClass" v-show="isOpen && hasContext">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'context-menu',
  props: {
    position: {
      required: false,
      type: String,
      default: 'bottom',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    contextMenuClass() {
      return `bg-gray-800 dark:bg-black context-menu-${this.position} rounded-lg shadow-xl p-2 absolute text-white text-xs opacity-100`;
    },
    contextMenuTriggerClass() {
      return this.hasContext ? 'context-menu-trigger cursor-help' : 'context-menu-trigger cursor-default';
    },
    hasContext() {
      return Array.isArray(this.$slots.default);
    },
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss">
.context-menu-top {
  @apply top-0 mb-10;
}
</style>
