<template>
  <footer class="pt-4 pb-20 w-full bg-white dark:bg-gray-900 dark:text-gray-200 print:hidden">
    <div class="px-4 mx-auto max-w-screen-xl">
      <made-with />
    </div>
  </footer>
</template>

<script>
export default {
  name: 'LayoutFooter',
};
</script>
