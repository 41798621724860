<template>
  <div class="my-4 mx-auto bg-transparent print:my-0 h-104 xs:h-104 sm:h-96 md:h-72 print:h-72">
    <div class="relative h-72 bg-white rounded-lg shadow-xl dark:bg-gray-900 sm:h-64 md:h-72 md:items-center print:flex md:flex print:shadow-none print:h-64">
      <!-- Left -->
      <div class="overflow-hidden relative w-full h-72 rounded-t-lg md:w-2/5 print:w-3/5 md:rounded-t-none print:rounded-t-none md:rounded-l-lg print:rounded-l-lg print:h-64">
        <!-- Background -->
        <div class="absolute inset-0 w-full h-screen bg-indigo-400 dark:bg-gray-700">
          <particles />
        </div>
        <!-- Content -->
        <div class="flex absolute inset-0 flex-1 justify-center items-center h-full text-white print:text-gray-700 dark:text-gray-800">
          <div class="flex">
            <div class="z-20 w-full text-center break-word md:px-10 print:px-0 print:text-left">
              <h1 class="text-5xl leading-snug text-white dark:text-black md:text-3xl lg:text-4xl xl:text-5xl print:text-3xl print:font-bold">{{ person.name }}</h1>
              <h2 class="text-2xl tracking-wide text-gray-800 print:text-xl">{{ person.title }}</h2>
              <div class="flex justify-center mt-4 print:flex-col print:justify-start print:mt-4">
                <div :key="contact.display" v-for="contact in contacts" class="z-20 social-icon-link">
                  <context-menu>
                    <a rel="noopener noreferrer" slot="trigger" target="_blank" :href="contact.url">
                      <i :class="`fa-fw icon-lg ${contact.icon}`"></i>
                      <span class="hidden text-gray-800 dark:text-gray-200 print:inline-block print:text-sm">{{ contact.label }}</span>
                    </a>
                    <span>{{contact.name}}</span>
                  </context-menu>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Right (White) Section -->
      <div class="items-center bg-white rounded-lg dark:bg-gray-900 md:w-3/5 print:w-3/5">
        <div class="p-16">
          <p class="text-gray-600 print:text-gray-700 dark:text-gray-500">
            <span class="font-medium text-gray-800 print:text-gray-800 dark:text-gray-100">Full Stack Developer</span> with {{ yearsInField }} experience in the field, where {{ yearsInFinance }} of those years were spent predominantly in
            <span class="font-medium text-gray-800 print:text-gray-900 dark:text-gray-100">fintech</span>.
          </p>
          <div class="text-left print:hidden">
            <p class="mt-10 leading-snug text-indigo-600 dark:text-indigo-500">Looking for a developer?</p>
            <a class="items-baseline font-medium text-indigo-900 dark:text-indigo-200 hover:text-indigo-700 focus:text-indigo-700 group" href="mailto:hello.jamal@icloud.com?bcc=hello.jamal@icloud.com&subject=re%3A%20I%20Have%20An%20Idea%20I%27d%20like%20to%20Discuss&body=Hello%20Jamal%21%0D%0A%0D%0AI%20recently%20stumbled%20upon%20your%20website%2C%20and%20I%20noticed%20you%20said%20you%20were%20looking%20for%20an%20interesting%20project%20to%20contribute%20to.%0D%0A%0D%0AI%20have%20an%20idea%20that%20I%27d%20like%20to%20run%20across%20you.%20I%20think%20it%20may%20pique%20your%20interest.%0D%0A%0D%0AFeel%20free%20to%20email%20me%20at%20your%20earliest%20convenience.%20Perhaps%20we%20can%20discuss%20in%20online%20if%20an%20in%20person%20meeting%20won%27t%20work.%0D%0A%0D%0AI%20look%20forward%20to%20speaking%20with%20you." target="_blank" rel="noopener noreferrer"><span>Get in touch</span><span class="ml-1 text-xs transition duration-500 ease-in-out group-hover:ml-2">&#x279c;</span></a>
          </div>
        </div>
        <svg class="hidden absolute inset-y-0 z-30 -ml-12 w-24 h-full text-white fill-current dark:text-gray-900 print:text-transparent md:block print:block" viewBox="0 0 100 100" preserveAspectRatio="none">
          <polygon points="50,0 100,0 50,100 0,100" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
const moment = require('moment');
require('moment-precise-range-plugin');

export default {
  name: 'resume-introduction',
  data() {
    return {
      person: {
        name: 'Jamal Alawes',
        title: 'Software Engineer',
        phone: '3068803088',
        email: 'hello.jamal@icloud.com',
      },
      yearsInField: moment.duration(moment('2012').diff(Date.now())).humanize(),
      yearsInFinance: moment.duration(moment(Date.now()).diff(moment('2016'))).years(),
    };
  },
  computed: {
    contacts() {
      return [
        {
          name: 'Email',
          icon: 'far fa-envelope',
          display: this.person.email,
          url: 'mailto:hello.jamal@icloud.com?bcc=hello.jamal@icloud.com&subject=re%3A%20I%20Have%20An%20Idea%20I%27d%20like%20to%20Discuss&body=Hello%20Jamal%21%0D%0A%0D%0AI%20recently%20stumbled%20upon%20your%20website%2C%20and%20I%20noticed%20you%20said%20you%20were%20looking%20for%20an%20interesting%20project%20to%20contribute%20to.%0D%0A%0D%0AI%20have%20an%20idea%20that%20I%27d%20like%20to%20run%20across%20you.%20I%20think%20it%20may%20pique%20your%20interest.%0D%0A%0D%0AFeel%20free%20to%20email%20me%20at%20your%20earliest%20convenience.%20Perhaps%20we%20can%20discuss%20in%20online%20if%20an%20in%20person%20meeting%20won%27t%20work.%0D%0A%0D%0AI%20look%20forward%20to%20speaking%20with%20you.',
        },
        {
          name: 'Phone',
          icon: 'fas fa-mobile-alt',
          display: this.person.phone,
          url: `tel:${this.person.phone}`,
        },
        {
          name: 'GitHub',
          icon: 'fab fa-github',
          display: 'github.com/jalawes',
          url: 'https://www.github.com/jalawes',
        },
        {
          name: 'LinkedIn',
          icon: 'fab fa-linkedin-in',
          display: 'linkedin.com/in/jamal-alawes',
          url: 'https://www.linkedin.com/in/jamal-alawes',
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.social-icon-link {
  @apply inline-block flex mr-3 text-indigo-800 print:block print:items-baseline print:mr-0 print:text-gray-900 print:space-x-2 print:space-y-2 hover:text-gray-200 dark:text-gray-900 dark:hover:text-gray-600;
}
</style>
