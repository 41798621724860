<template>
  <content-section>
    <span slot="heading">Extracurricular</span>
    <card v-for="activity in activities" :key="activity.id">
      <div class="mb-6 last:mb-0">
        <h4 class="text-sm tracking-wide leading-none text-indigo-500 print:text-gray-800 dark:text-indigo-500">{{ activity.title }}</h4>
        <h5 class="mb-1 text-lg font-medium tracking-wide text-gray-700 print:text-gray-900 dark:text-gray-100"><a :href="activity.url" class="hover:text-gray-500">{{ activity.institution }}</a></h5>
        <div class="flex">
          <duration-indicator :start="activity.dates.start" :end="activity.dates.end" />
        </div>
      </div>
    </card>
  </content-section>
</template>

<script>
export default {
  name: 'resume-extracurricular',
  data() {
    return {
      activities: [
        {
          id: 1,
          institution: 'Canada Learns to Code',
          title: 'Mentor',
          dates: {
            start: '11/01/2017',
            end: 'Present',
          },
          url: 'https://www.canadalearningcode.ca/',
        },
        {
          id: 2,
          institution: 'Codementor.io',
          title: 'Mentor',
          dates: {
            start: '04/01/2020',
            end: 'present',
          },
          url: 'https://www.codementor.io/',
        },
        {
          id: 3,
          institution: 'Digitized - University of Saskatchewan',
          title: 'Workshop Leader, Guest Speaker',
          dates: {
            start: '05/28/2018',
          },
          url: 'https://digitized.usask.ca/',
        },
      ],
    };
  },
  methods: {
    dates(activity) {
      if (activity.dates.end) {
        return `${activity.dates.start} to ${activity.dates.end}`;
      }
      return activity.dates.start;
    },
  },
};
</script>
