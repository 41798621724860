<template>
  <div>
    <context-menu>
      <div class="items-baseline text-sm tracking-tight text-gray-500 print:text-gray-700 cursor-info" slot="trigger">
        <i class="text-gray-600 print:text-gray-800 far fa-fw fa-calendar-alt"></i>
        {{ message }}
      </div>
      <span v-if="hasStartAndEnd">{{ duration }}</span>
    </context-menu>
  </div>
</template>

<script>
const moment = require('moment');

const mdy = (str) => moment(str, 'L');
const date = (str) => mdy(str).format('MMM YYYY');
const duration = (str) => moment.duration(str);

export default {
  name: 'duration-indicator',
  props: {
    start: {
      type: String,
      required: true,
    },
    end: {
      type: String,
      required: false,
    },
  },
  computed: {
    endType() {
      return typeof this.end;
    },
    duration() {
      if (!this.hasStartAndEnd) {
        return null;
      }
      if (moment.duration(this.difference).asYears() < 1) {
        return duration(this.difference).locale('en').humanize();
      }
      return `${this.years} years ${this.months} months`;
    },
    difference() {
      return this.endDate.diff(this.startDate);
    },
    endDate() {
      if (this.end && this.end.toLowerCase() === 'present') {
        return this.now;
      }
      return mdy(this.end);
    },
    now() {
      return moment();
    },
    startDate() {
      return mdy(this.start);
    },
    hasStartAndEnd() {
      return moment(this.startDate).isValid() && mdy(this.endDate).isValid() && moment.isDuration(duration(this.difference));
    },
    message() {
      if (this.end) {
        if (moment(this.end).isValid()) {
          return `${date(this.startDate)} to ${date(this.endDate)}`;
        }
        if (this.end.toLowerCase() === 'present') {
          return `${date(this.startDate)} to Present`;
        }
      }
      return date(this.startDate);
    },
    months() {
      return Math.round(duration(this.difference).locale('en').asMonths() % 12);
    },
    years() {
      return Math.round(duration(this.difference).locale('en').asYears());
    },
  },
};
</script>
