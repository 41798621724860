<template>
  <div class="flex items-baseline text-sm text-gray-500 print:text-gray-700">
    <i class="flex mr-1 text-gray-600 print:text-gray-800 fas fa-fw fa-laptop-house"></i>
    <p class="flex-1">Remote</p>
  </div>
</template>

<script>
export default {
  name: 'remote-indicator',
};
</script>
