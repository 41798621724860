<template>
  <content-section class="page-break-inside-avoid">
    <span slot="heading" id="experience">Experience</span>
    <card v-for="job in experience.jobs" :key="job.company.name">
      <resume-job :job="job" />
    </card>
  </content-section>
</template>

<script>
export default {
  name: 'resume-experience',
  data() {
    return {
      experience: {
        title: 'Professional Experience',
        jobs: [
          {
            company: {
              name: 'CareerFoundry',
              homepage: 'https://careerfoundry.com',
            },
            title: 'Consultant',
            dates: {
              start: '06-27-2019',
              end: 'present',
            },
            responsibilities: [
              'Regularly review student exercises submitted through the CareerFoundry platform',
              'Evaluate, provide feedback and constructive criticism on students\' submissions',
              'Provide students with guidance to improve their work through reviews and industry insights',
              'Routinely perform code reviews and check for students\' fundamental understanding of concepts',
              'Suggest supplemental learning materials and personal insights in areas where students are struggling',
              'Advising students specifically on career-related topics, and help prepare them to enter the industry',
            ],
            locations: ['Berlin, DE'],
            remote: true,
          },
          {
            company: {
              name: 'Coconut Software',
              homepage: 'https://www.coconutsoftware.com',
            },
            title: 'Software Developer & Team Lead',
            dates: {
              start: '11-01-2017',
              end: '04-01-2020',
            },
            responsibilities: [
              'Design, code, and test major features in collaboration with Team Members, Design Engineers, Documentation Specialists, Application Scientists, and other members of the development and technical team to deliver complex changes',
              'Define, design, and implement, multi-tiered object-oriented distributed software applications',
              'Provide leadership for the development team',
              'Debug and test software implementation',
              'Provide design documents and documentation to ensure efficient maintenance and code reuse',
              'Interact with key partners and internal users regarding timelines, technical issues, and infrastructure integration',
              'Mentor and supervise junior developers',
              'Provide recommendations for improvements in the overall development process',
              'Ensure work progress to schedule and keep (internal) customers informed of the projects progress',
              'Actively participate in team meetings and offer recommendations / ideas on design projects',
            ],
            locations: ['Saskatoon, SK'],
            remote: true,
          },
          {
            company: {
              name: 'Analusis Consulting Inc',
              homepage: 'https://ca.linkedin.com/company/analusis-consulting-inc.',
            },
            title: 'Software Developer',
            dates: {
              start: '01-15-2017',
              end: '11-01-2017',
            },
            responsibilities: [
              'Participated in the entire phase of development cycle from Design, Testing, Q&A, and Deployment',
              'Collaborated with and provided mentorship to the local team of developers',
              'Participated in the sourcing and documentation of requirements through stakeholder engagement, research, and evaluation',
              'Produced both technical and non-technical documentation, and hosted weekly software demo presentations',
              'Developed and Improved frontend views using Laravel’s Blade templating engine and Vue.js',
              'Developed backend website functionality using PHP & Eloquent/MySQL',
              'Implemented isolated backend functionality using RESTful web APIs',
              'Improved quality processes, including introduction of automated testing',
            ],
            locations: ['Vancouver, BC', 'Kampala, UG'],
            remote: true,
          },
          {
            company: {
              name: 'Clouds ECB',
              homepage: 'https://shop.cloudsvancouver.com',
            },
            title: 'Software Developer',
            dates: {
              start: '01-01-2014',
              end: '04-01-2016',
            },
            responsibilities: [
              'Designed textual and graphical content, including product illustrations',
              'Developed website layout, application and functions layout',
              'Integrated cross browser compatible applications, and secure payment gateways',
              'Developed product inventory, and point of sale (POS) applications',
            ],
            locations: ['Vancouver, BC'],
          },
          {
            company: {
              name: 'L&W Landscaping',
              homepage: 'https://www.lwlandscaping.ca',
            },
            title: 'Web Developer & Marketing Specialist',
            dates: {
              start: '01-01-2014',
              end: 'Present',
            },
            responsibilities: [
              'Designed textual and graphical content, including product illustrations',
              'Developed website layout, application and functions layout',
              'Integrated cross browser compatible applications and secure payment gateways in the website',
              'Developed product inventory and point of sale applications (POS)',
            ],
            locations: ['Vancouver, BC'],
          },
        ],
      },
    };
  },
};
</script>
