<template>
  <div>
    <div class="mb-20">
      <resume-introduction />
    </div>
    <resume-testimonials />
    <resume-worked-at />
    <div class="flex-no-wrap md:flex print:block">
      <div class="md:w-2/3 md:mr-6 print:mr-0 print:w-full">
        <resume-skills-overview />
        <resume-experience />
        <resume-education />
      </div>
      <div class="md:w-1/3 print:w-full">
        <resume-technologies />
        <resume-awards />
        <resume-extracurricular-activities />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'resume',
};
</script>
