import Vue from 'vue';
import Router from 'vue-router';
import Resume from './views/Resume.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      component: Resume,
      name: 'resume',
      path: '/',
    },
  ],
});
