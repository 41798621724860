<template>
  <content-section>
    <span slot="heading">Awards</span>
    <card v-for="award in awards.awarded" :key="award.id">
      <div class="flex mb-2">
        <div class="w-full">
          <div class="flex justify-between items-baseline flex-no-wrap">
            <div class="flex-1">
              <h5 class="text-sm tracking-wide text-indigo-500 print:text-gray-800 dark:text-indigo-500">{{ award.institution }}</h5>
              <h6 class="text-lg font-medium tracking-wide text-gray-700 print:text-gray-900 dark:text-gray-200">{{ award.name }}</h6>
            </div>
          </div>
        </div>
      </div>
      <duration-indicator :start="award.date" />
    </card>
  </content-section>
</template>

<script>
export default {
  name: 'resume-awards',
  data() {
    return {
      awards: {
        title: 'Awards',
        awarded: [
          {
            id: 1,
            institution: 'University of British Columbia',
            name: 'Natural Science and Engineering Research (NSERC) Undergraduate Award Candidate',
            date: '01-28-2016',
          },
          {
            id: 2,
            institution: 'Indspire',
            name: 'Building Brighter Futures Award',
            date: '11-06-2015',
          },
          {
            id: 3,
            institution: 'Indspire',
            name: 'Building Brighter Futures Award',
            date: '11-01-2014',
          },
        ],
      },
    };
  },
};
</script>
