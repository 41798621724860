<template>
  <div class="mt-20 bg-gray-800 dark:bg-gray-900">
    <div class="flex p-10">
      <div class="flex-1">
        <p class="text-gray-500">Find me on <a :href="links.github.url" class="underline hover:text-gray-600" target="_blank" rel="noopener noreferrer">GitHub</a>.</p>
      </div>
      <div class="flex-1 text-right">
        <p class="text-gray-500">Copyright © {{ new Date().getFullYear() }} Jamal Alawes.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-footer',
  data() {
    return {
      address: {
        street: '13688 100 Ave',
        streetTwo: '#3106',
        city: 'Surrey',
        province: 'BC',
      },
      email: 'jamal.alawes@me.com',
      links: {
        github: {
          url: 'https://www.github.com/jalawes',
          icon: 'fab fa-fw fa-github',
        },
        linkedIn: {
          url: 'https://www.linkedin.com/in/jamal-alawes/',
          icon: 'fab fa-fw fa-linkedin',
        },
        email: {
          url: 'mailto:jamal.alawes@me.com',
          icon: 'fas fa-fw fa-envelope',
        },
      },
      name: 'Jamal Alawes',
      phoneNumber: '(306) 880 3088',
      title: 'Software Developer',
    };
  },
};
</script>
