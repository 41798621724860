<template>
  <content-section>
    <span slot="heading">References</span>
    <card>
      <div class="mt-5" v-for="reference in references.references" :key="reference.name">
        <flex items-baseline justify-between flex-no-wrap :title="reference.name" :subtitle="reference.position" />
        <p class="text-sm text-gray-500">{{ reference.phone }}</p>
      </div>
    </card>
  </content-section>
</template>

<script>

export default {
  name: 'resume-references',
  data() {
    return {
      references: [
        {
          name: 'Corey Sanford',
          position: 'Senior Software Developer',
          phone: '(555) 555 5555',
        },
        {
          name: 'Romeo Iula',
          position: 'Chief Technology Officer',
          phone: '(555) 555 5555',
        },
      ],
    };
  },
};
</script>
