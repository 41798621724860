<template>
  <content-section>
    <span slot="heading">Education</span>
    <div class="space-y-4 md:flex md:flex-1 md:space-x-4 md:space-y-0">
      <div v-for="institution in education.institutions" :key="`${institution.degree}`" class="flex-1">
        <card>
          <div class="flex justify-between items-baseline flex-no-wrap">
            <div class="flex">
              <div class="w-full">
                <div class="flex justify-between items-baseline flex-no-wrap">
                  <div class="flex-1">
                    <h4 class="text-sm tracking-wide text-indigo-500 print:text-gray-800 dark:text-indigo-500">{{ institution.degree }}</h4>
                    <h5 class="text-lg font-semibold tracking-wide text-gray-700 hover:text-gray-500 print:text-gray-900 dark:text-gray-200"><a :href="institution.website">{{ institution.name }}</a></h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <duration-indicator class="mt-1" :start="institution.dates.start" :end="institution.dates.end" />
        </card>
      </div>
    </div>
  </content-section>
</template>

<script>
export default {
  name: 'resume-education',
  data() {
    return {
      education: {
        title: 'Education',
        institutions: [
          {
            dates: {
              start: '09/08/2012',
              end: '05/26/2016',
            },
            degree: 'Bachelor of Kinesiology',
            name: 'University of British Columbia',
            website: 'https://www.ubc.ca/',
          },
          {
            dates: {
              start: '12/23/2016',
            },
            degree: 'Web Development Certificate',
            name: 'Lighthouse Labs',
            website: 'https://www.lighthouselabs.ca/',
          },
        ],
      },
    };
  },
};
</script>
