<template>
  <transition name="fade">
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: 'fade-in-out',
};
</script>

<style scoped lang="scss">
.fade-enter {
  visibility: hidden;
}

.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  transition: 0.4s;
}

.fade-enter-active {
  visibility: visible;
  transition: 0.2s ease-in;
}
</style>
