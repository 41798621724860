<template>
  <div class="mb-6 last:mb-0 page-break-inside-avoid print:flex print:flex-col">
    <!-- Top Content -->
    <div class="flex order-1 mb-2 last:mb-0">
      <div class="w-full">
        <div class="flex">
          <div class="w-full flex-break">
            <div class="flex-1">
              <div class="flex justify-between items-baseline flex-no-wrap">
                <div class="flex-1">
                  <h5 class="text-sm tracking-wide text-indigo-500 print:text-gray-800 dark:text-indigo-500">{{ job.title }}</h5>
                  <h4 class="text-lg font-semibold tracking-wide text-gray-700 hover:text-gray-500 print:text-gray-900 dark:text-gray-100 dark:hover:text-gray-300">
                    <a :href="job.company.homepage" class="hover:text-gray-700" target="_blank">{{ job.company.name }}</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Bottom Content -->
    <div class="flex flex-col order-3 mb-4 last:mb-0 print:mt-4" v-show="job.responsibilities.length && display">
      <div class="-ml-10 w-full">
        <ul class="fa-ul">
          <li class="mb-2 text-gray-600 last:mb-0 print:text-gray-900 dark:text-gray-600" v-for="responsibility in job.responsibilities" :key="responsibility">
            {{ responsibility }}
          </li>
        </ul>
        <ul>
        </ul>
      </div>
    </div>
    <div class="flex-none order-2 space-y-1 w-full sm:space-y-0 sm:flex sm:flex-wrap sm:justify-start sm:space-x-2 print:space-x-2 print:flex-row print:flex print:justify-start print:space-y-0 print:items-center">
      <location-indicator :locations="job.locations" />
      <remote-indicator v-if="job.remote" />
      <duration-indicator :start="job.dates.start" :end="job.dates.end" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'resume-job',
  props: {
    job: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      display: false,
    };
  },
  computed: {
    identifier() {
      return this.job.company.name.split(' ').join('').replace('&', '');
    },
    message() {
      return this.display ? 'Hide Details' : 'Show Details';
    },
  },
  methods: {
    toggle() {
      this.display = !this.display;
      document.getElementById(this.identifier).scrollIntoView({ alignToTop: true, behavior: 'smooth' });
    },
  },
};
</script>

<style lang="scss">
  .mybutton {
    transform: translateX(-50px);
  }
</style>
