<template>
  <div class="flex z-10">
    <div class="container py-2 px-6 mx-auto">
      <div class="print:hidden">
        <a href="/" slot="left">
          <img alt="Jamal Alawes Logo" class="h-8" :src="logo">
        </a>
      </div>
      <div class="mw-full"><!-- Search --></div>
      <div class="mw-full"><!-- Right side --></div>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/img/jamalalawes.dev-logo-50.png';

export default {
  name: 'navigation-bar',
  data() {
    return {
      logo,
    };
  },
};
</script>
